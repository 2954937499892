import React from "react";
import PropTypes from "prop-types";
import {setLanguage} from "redux-i18n"
import { connect } from "react-redux";

const Footer = ({setLanguage, translate}) => {
  return (
    <footer className="full_footer p_absoulte">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-6 col-6">
            <ul className="list-unstyled social_icon social_icon_two">
              <li>
                <a onClick={() => setLanguage("tr")} target="_blank">
                  TR
                </a>
              </li>
              <li>
                <a
                  onClick={() => setLanguage("en")}
                  target="_blank"
                >
                  EN
                </a>
              </li>
            </ul>
            <ul className="list-unstyled social_icon social_icon_two mt-2">
              <li>
                <a href="https://twitter.com/seemeetlive" target="_blank">
                  <i className="social_twitter" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/seemeetlive"
                  target="_blank"
                >
                  <i className="social_instagram" />
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-6 col-6">
            <div className="pr_details_nav h_slider_nav align-items-center">
              <span className="prev" id="moveUp">
                {translate("previous")}
              </span>
              <span className="next moveUp" id="moveDown">
                {translate("next")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

const mapDispatchToProps = {setLanguage}

export default connect(null, mapDispatchToProps)(Footer);
