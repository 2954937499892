import React from "react";
import { BASE_API } from "../../../helpers/clientUrl";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";

const Header = (props, context) => {
  const { lang } = props;
  const { t } = context;
  return (
    <>
      <header className="header_area p_absoulte m_p sticky_nav">
        <nav className="navbar navbar-expand-lg" id="header">
          <div className="container-fluid">
            <div className="logo_info">
              <a href={"/"} className="navbar-brand logo_two">
                <img src="img/logo.png" srcSet="img/logo2x.png 2x" alt="" />
                <img
                  src="img/dark_logo.png"
                  srcSet="img/dark_logo2x.png 2x"
                  alt=""
                />
              </a>
              <div className="h_account_info">
                <a href={"/"}>{t("stayOnline")}</a>
                <a href={"/"}>{t("meetSafe")}</a>
              </div>
            </div>
            <button
              className="navbar-toggler collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="menu_toggle">
                <span className="hamburger">
                  <span />
                  <span />
                  <span />
                </span>
                <span className="hamburger-cross">
                  <span />
                  <span />
                </span>
              </span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto menu">
                <li className="nav-item">
                  <a className="nav-link" href={"/"}>
                  {t("homepageCap")}
                  </a>
                </li>
                {/* <li className="nav-item active">
                  <a className="nav-link" href={BASE_API + "/register"}>
                    KAYIT OL
                  </a>
                </li> */}
                <li className="nav-item">
                  <a
                    className="nav-link dropdown-toggle"
                    href={`${BASE_API}/login?lang=${lang}`}
                  >
                    {t("loginCap")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>

      <section className="title_area_two2 title_area_three">
        <div className="overlay" />
        <div className="container custom_container">
          <div className="title_content"></div>
        </div>
      </section>
    </>
  );
};

Header.contextTypes = {
  t: PropTypes.func.isRequired
}

const mapStateToProps = store => ({
  lang: store.i18nState.lang,
});

export default withRouter(connect(mapStateToProps)(Header));
