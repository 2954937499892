import { BASE_API } from "../helpers/clientUrl";

export const mainMenuItems = lang => ([
  {
    title: "homepage",
    to: "#s-first",
    key: "toplantiya-katil",
    className: "sm-home active",
  },
  {
    title: "login",
    to: `${BASE_API}/giris-yap?lang=${lang}`,
    key: "toplanti-olustur",
    className: "sm-sign-in",
  },
  {
    title: "register",
    to: `${BASE_API}/kayit-ol?lang=${lang}`,
    key: "toplantilar",
    className: "sm-sign-up",
  },
  {
    title: "downloadApp",
    to: "#s-fifth",
    key: "canli-yayinlar",
    className: "sm-download",
  },
]);