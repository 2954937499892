import React from "react";
import Header from "./header";
import Footer from "./footer";
import Content from "./content";

const FormContainer = ({}) => {

  return (
    <div className="body_wrapper">
      <Header />
      <Content />
      <Footer />
    </div>
  );
};

export default FormContainer;
