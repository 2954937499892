import { AUTH_INFORMATIONS, AUTH_LOGOUT } from "./types";

const initialState = {
  informations: {}
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_INFORMATIONS:
      const informations = { ...state.informations, ...action.payload };
      return {
        ...state,
        informations
      };
    case AUTH_LOGOUT:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
