import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

const NavigationMenu = (props, context) => {
  const { t } = context;
  const { items } = props;

  return (
    <ul className="seemeet-sidebar-mid-nav">
      {items.map((item, index) => (
        <li data-menuanchor="s-fifth" key={index}>
          <a
            href={item.to}
            className={item.className}
          >
            <div className="sm-icon-bg" />
            <div className="sm-nav-text">{t(item.title)}</div>
          </a>
        </li>
      ))}
    </ul>
  );
};

NavigationMenu.propTypes = {
  items: PropTypes.array.isRequired
};

NavigationMenu.defaultProps = {
  items: []
};

NavigationMenu.contextTypes = {
  t: PropTypes.func.isRequired
}

export default withRouter(NavigationMenu);
