import React from 'react';

const Image = props => {
  const {src, width, out} = props;
  var srcVal = src.indexOf('http') != -1 || src.indexOf('https') != -1 ? src : '/img/' + src;
  if (out) srcVal = 'https://static.metodbox.com/templates/img/' + src;
  return <img {...{...props, out: 'true'}} src={srcVal} width={width} />;
};

export default Image;
