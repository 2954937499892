import React from "react";
import { mainMenuItems } from "../../../constants/sidebarItems";
import NavigationMenu from "../navigationMenu";
import { connect } from "react-redux";

const Sidebar = ({lang}) => {
  
  return (
    <div className="seemeet-sidebar">
      <div className="seemeet-sidebar-logo">
        <img
          src="/img/seemeet-logo-white-square.svg"
          alt="SeeMeet"
          width={50}
          className="sm-logo-white"
        />
        <img
          src="/img/seemeet-logo-black-square.svg"
          alt="SeeMeet"
          width={50}
          className="sm-logo-black"
        />
      </div>
      <NavigationMenu items={mainMenuItems(lang)} />
    </div>
  );
};

const mapStateToProps = store => ({
  lang: store.i18nState.lang,
});

export default connect(mapStateToProps)(Sidebar);
