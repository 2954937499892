import React from "react";
import PropTypes from "prop-types";

const Footer = ({}, context) => {
  const { t } = context;
  return (
    <footer className="footer_area">
      <div className="container">
        <div className="footer_top">
          <a href="#" className="f_logo">
            <img src="img/logo.png" srcSet="img/logo2x.png 2x" alt="" />
          </a>
          <p>
            {t("productText1")}{" "}
            <a href="https://bktomorrow.com/" target="_blank">
              BK Mobil A.Ş.
            </a>{" "}
            {t("productText2")}
          </p>
          <p>
          {t("productHelp")}
          </p>
          <ul className="list-unstyled w_account_info">
            <li>
              <i className="icon_phone" />
              {t("helpDesk")}: 0 850 255 06 25
              <br /> {t("homeOffice")}: (+90) 312 265 00 03
            </li>
            <li>
              <i className="icon_pin_alt" />
              <a href="#">
              {t("address")}
              </a>
            </li>
            <li>
              <i className="icon_mail_alt" />
              <a href="mailto:info@bktomorrow.com">info@bktomorrow.com</a>
            </li>
          </ul>
          <ul className="list-unstyled social_link">
            <li>
              <a href="https://twitter.com/seemeetlive" target="_blank">
                <i className="social_twitter" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/seemeetlive/" target="_blank">
                <i className="social_instagram" />
              </a>
            </li>
          </ul>
        </div>
        <div className="footer_bottom">
          <p>
            Copyright © 2020 <a href="/">SeeMeet</a>&nbsp; | &nbsp;{t("copyright")}
          </p>
        </div>
      </div>
    </footer>
  );
};

Footer.contextTypes = {
  t: PropTypes.func.isRequired
}

export default Footer;
