import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { BASE_API } from "../../../helpers/clientUrl";
import { connect } from "react-redux";

const Home = (props, context) => {
  const { t } = context;
  const { lang } = props;

  return (
    <div id="wavescroll">
      <section
        className="section wave_one_section_one"
        data-anchor="s-first"
        style={{ verticalAlign: "middle" }}
      >
        <div className="t_first p_absoulte">
          <img
            className="layer layer2"
            data-depth="-0.20"
            src="img/home_one/triangle_shap_one.png"
            alt=""
          />
        </div>
        <div className="t_two p_absoulte">
          <img
            className="layer layer2"
            data-depth="0.30"
            src="img/home_one/triangle_shap_two.png"
            alt=""
          />
        </div>
        <div className="t_three p_absoulte">
          <img
            className="layer layer2"
            data-depth="0.30"
            src="img/home_three/tr_two.png"
            alt=""
          />
        </div>
        <div className="intro">
          <div className="container custom_container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="w_content">
                  <img
                    className="circle_line"
                    src="img/home_one/circle_line.png"
                    alt=""
                  />
                  <h2>SeeMeet</h2>
                  <p>
                    {t("sectionOne1")}
                  </p>
                  <p>
                    {t("sectionOne2")}
                  </p>
                  <a
                    href={`${BASE_API}/giris-yap?lang=${lang}`}
                    // target="_blank"
                    className="p_btn"
                  >
                    {t("loginCap")}
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="w_img_one  text-center">
                  <img src="img/img1.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="section wave_two_section_two" data-anchor="s-second">
        <div id="particles-js" className="p_absoulte" />
        <img
          className="t_two p_absoulte"
          src="img/home_one/triangle_shap_two.png"
          alt=""
        />
        <img
          className="t_shap p_absoulte"
          src="img/home_three/shap.png"
          alt=""
        />
        <img
          className="b_shap p_absoulte"
          src="img/home_three/shap_two.png"
          alt=""
        />
        <img
          className="dot_one p_absoulte"
          src="img/home_three/dot.png"
          alt=""
        />
        <img
          className="dot_two p_absoulte"
          src="img/home_three/dot-1.png"
          alt=""
        />
        <div className="s_round r_one p_absoulte" />
        <div className="s_round r_two p_absoulte" />
        <div className="s_round r_three p_absoulte" />
        <div className="s_round r_four p_absoulte" />
        <div className="s_round r_five p_absoulte" />
        <div className="s_round r_six p_absoulte" />
        <div className="s_round r_seven p_absoulte" />
        <div className="s_round r_eight p_absoulte" />
        <div className="s_round r_nine p_absoulte" />
        <div className="s_round r_ten p_absoulte" />
        <div className="s_round r_eleven p_absoulte" />
        <div className="intro">
          <div className="container custom_container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="w_portfolio_img">
                  <div className="round p_absoulte" />
                  <img src="img/SeeMeet.jpg" alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="w_content_two">
                  <h2>{t("sectionTwoTitle")} </h2>
                  <p> {t("sectionTwo1")}</p>
                  <p>
                    {" "}
                    {t("sectionTwo2")}
                  </p>
                  <p>
                    {" "}
                    {t("sectionTwo3")}
                  </p>
                  <p>
                    {" "}
                    {t("sectionTwo4")}
                  </p>
                  <a
                    href={`${BASE_API}/giris-yap?lang=${lang}`}
                    // target="_blank"
                    className="p_btn"
                  >
                    {t("loginCap")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section wave_two_section_three third" data-anchor="s-third">
        <div className="t_first p_absoulte">
          <img
            className="layer layer2"
            data-depth="-0.20"
            src="img/home_three/pijom.png"
            alt=""
          />
        </div>
        <div className="t_two p_absoulte">
          <img
            className="layer layer2"
            data-depth="0.50"
            src="img/home_three/plan.png"
            alt=""
          />
        </div>
        <div className="t_four p_absoulte">
          <img
            className="layer layer2"
            data-depth="0.30"
            src="img/home_three/triangle.png"
            alt=""
          />
        </div>
        <div className="t_five p_absoulte">
          <img
            className="layer layer2"
            data-depth="0.10"
            src="img/home_three/square.png"
            alt=""
          />
        </div>
        <div className="intro">
          <div className="container custom_container">
            <h2 className="s_section_title">{t("sectionThirdTitle")}</h2>
            <div className="row row sm-eq-h-cards">
              <div className="col-lg-6 col-md-6">
                <div className="scroll_service_item">
                  <div className="number">1.</div>
                  <img
                    className="icon p_absoulte"
                    src="img/home_three/s_icon.png"
                    alt=""
                  />
                  <a href="#">
                    <h3 style={{ fontSize: "24px", minHeight: 64 }}>
                      {t("sectionThird1")}
                    </h3>
                  </a>
                  <p style={{minHeight: 140}}>
                    {t("sectionThird2")}
                  </p>
                  <div className="d-flex justify-content-between">
                    <a 
                      href={`${BASE_API}/giris-yap?lang=${lang}`} 
                      className="d-flex align-items-center justify-content-center p_btn two-grid w-45"
                    >
                      {t("startNowCap")}
                    </a>
                    <a 
                      href="doc/SEEMEET_GENEL_KULLANIM_KILAVUZU.pdf" 
                      download 
                      className="d-flex align-items-center justify-content-center p_btn two-grid w-45">
                      {t("userGuide")}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="scroll_service_item">
                  <div className="number">2.</div>
                  <a href="#">
                    <h3 style={{ fontSize: "24px", minHeight: 64 }}>{t("companyUsage")} </h3>
                  </a>
                  <img
                    className="icon p_absoulte"
                    src="img/home_three/s_icon_2.png"
                    alt=""
                  />
                  <p style={{minHeight: 140}}>
                    {t("sectionThird3")}
                  </p>
                  <div className="d-flex justify-content-between">
                    <a 
                      href={`${BASE_API}/giris-yap?lang=${lang}`} 
                      className="d-flex align-items-center justify-content-center p_btn two-grid w-45">
                      {t("startNowCap")}
                    </a>
                    <a 
                      href="doc/SeeMeetKurumsalIP-17122021.pdf" 
                      download 
                      className="d-flex align-items-center justify-content-center p_btn two-grid w-45">
                      {t("fireWallDoc")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section wave_one_section_four wave_two_section_four" data-anchor="s-four">
        <div className="bg-title">
          <div className="layer layer2" data-depth="-0.20">
            {t("sectionFourTitle")}
          </div>
        </div>
        <div className="t_first p_absoulte">
          <img
            className="layer layer2"
            data-depth="-0.20"
            src="img/home_three/h_pijom1.png"
            alt=""
          />
        </div>
        <div className="t_two p_absoulte">
          <img
            className="layer layer2"
            data-depth="0.50"
            src="img/home_three/plan.png"
            alt=""
          />
        </div>
        <div className="t_four p_absoulte">
          <img
            className="layer layer2"
            data-depth="0.30"
            src="img/home_three/h_pijom2.png"
            alt=""
          />
        </div>
        <div className="t_five p_absoulte">
          <img
            className="layer layer2"
            data-depth="0.10"
            src="img/home_one/triangle_shap_five.png"
            alt=""
          />
        </div>
        <div className="intro">
          <div className="container custom_container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="w_content_two">
                  <h2 style={{ fontSize: "34px" }}>
                    {t("sectionFourTitle")}
                  </h2>
                  <p>
                    {t("sectionFour1")}
                  </p>
                  <p>
                    {t("sectionFour2")}
                  </p>
                  <a
                    href={`${BASE_API}/toplantiya-katil?lang=${lang}`}
                    style={{ width: 250 }}
                    className="p_btn mb-2"
                  >
                    {t("joinMeetingCap")}
                  </a>{" "}
                  <a
                    href={`${BASE_API}/toplanti-olustur?lang=${lang}`}
                    style={{ width: 250 }}
                    className="p_btn"
                  >
                    {t("createMeetingCap")}
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="w_mockup_img">
                  <div className="img_screen one">
                    <img src="img/screen1.png" alt="" />
                  </div>
                  <div className="img_screen two">
                    <img src="img/screen2.png" alt="" />
                  </div>
                  <div className="img_screen three">
                    <img src="img/screen3.png" alt="" />
                  </div>
                  <img
                    style={{ right: -28 }}
                    className="img_four"
                    src="img/screen4.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*      <div class="section wave_two_section_five">
      <div class="bg-title">
          <div class="layer layer2" data-depth="-0.20">SeeMeet Video</div>
      </div>
      <div class="t_first p_absoulte"><img class="layer layer2" data-depth="-0.20" src="img/home_three/wave.png" alt=""></div>
      <div class="t_two p_absoulte"><img class="layer layer2" data-depth="0.20" src="img/home_three/blue_plan.png" alt=""></div>
      <div class="intro">
          <div class="container custom_container">
              <h2 class="s_section_title text-center">SeeMeet Video</h2>
              <div class="sc_video_inner">
                  <img class="p_absoulte video_bg" src="img/home_three/video_bg.png" alt="">
                  <div class="dot_l p_absoulte"><img class="layer layer2" data-depth="0.20" src="img/home_three/v_dot.png" alt=""></div>
                  <img class="dot_r p_absoulte" src="img/home_three/v_dot.png" alt="">
                  <a href="https://www.youtube.com/watch?v=AXJ8cK8ovUo" class="v_icon popup_youtube">
                      <span class="dot_animation"></span>
                      <img src="img/home_three/video_icon.png" alt="">
                  </a>
              </div>
          </div>
      </div>
  </div> */}
      <div className="section wave_two_section_six" data-anchor="s-fifth">
        <div className="t_first p_absoulte">
          <img
            className="layer layer2"
            data-depth="0.20"
            src="img/home_three/clients_plan_bottom.png"
            alt=""
          />
        </div>
        <div className="t_two p_absoulte">
          <img
            className="layer layer2"
            data-depth="0.20"
            src="img/home_three/clients_plan.png"
            alt=""
          />
        </div>
        <div className="intro">
          <div className="container custom_container">
            <h2 className="s_section_title">{t("downloadApps")}</h2>
            <div className="row">
              <div className="col-lg-6">
                <div className="sm-box-black">
                  <div className="text-24 mb-4 bold">{t("desktop")}</div>
                  <p style={{minHeight: 90, marginBottom: "3em"}}>
                    {t("downloadText1")}
                  </p>
                  <div className="text-center mt-4 mb-4">
                    <img
                      src="img/sm-img-desktop-app.jpg"
                      alt="Masaüstü"
                      className="m-auto"
                    />
                  </div>
                  <a className="sm-btn-border d-block">
                    {t("soon")}
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="sm-box-black">
                  <div className="text-24 mb-4 bold">{t("mobileApps")}</div>
                  <p>{t("downloadText2")}</p>
                  <p>
                    {t("downloadText3")}
                  </p>
                  <div className="text-center mt-4 mb-4">
                    <img
                      src="img/sm-img-mobile-app.jpg"
                      alt={t("mobileApps")}
                      className="m-auto"
                    />
                  </div>
                  <a 
                    href="https://apps.apple.com/tr/app/seemeet/id1509099785?l=tr" 
                    className="sm-btn-border d-block"
                    target="_blank"
                  >
                    {t("downloadApp")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section wave_two_section_seven" data-anchor="s-seven">
        <div className="t_first p_absoulte">
          <img
            className="layer layer2"
            data-depth="-0.20"
            src="img/home_three/round.png"
            alt=""
          />
        </div>
        <div className="t_two p_absoulte">
          <img
            className="layer layer2"
            data-depth="0.20"
            src="img/home_three/clients_plan.png"
            alt=""
          />
        </div>
        <div className="t_three p_absoulte">
          <img
            className="layer layer2"
            data-depth="-0.20"
            src="img/home_three/blue_plan.png"
            alt=""
          />
        </div>
        <img
          className="dot_one p_absoulte"
          src="img/home_three/dot.png"
          alt=""
        />
        <img
          className="dot_two p_absoulte"
          src="img/home_three/dot-1.png"
          alt=""
        />
        <div className="intro">
          <div className="container custom_container">
            <div className="row">
              <div className="col-lg-12">
                <div className="w_content_two w_contact_text">
                  <h2>{t("contactUs")}</h2>
                  <p>
                    {t("productHelp")}
                  </p>
                  <ul className="list-unstyled w_account_info">
                    <li>
                      <i className="icon_phone" />
                      {t("helpDesk")}: 0 850 255 06 25
                      <br /> {t("homeOffice")}: (+90) 312 265 00 03
                    </li>
                    <li>
                      <i className="icon_pin_alt" />
                      <a href="#">
                        {t("address")}
                      </a>
                    </li>
                    <li>
                      <i className="icon_mail_alt" />
                      <a href="mailto:info@bktomorrow.com">
                        info@bktomorrow.com
                      </a>
                    </li>
                  </ul>
                  <p>
                    {t("productText1")}{" "}
                    <a href="http://bktomorrow.com/" target="_blank">
                      BK Mobil A.Ş.
                    </a>{" "}
                    {t("productText2")}
                  </p>
                </div>
              </div>
              <div className="footer_bottom">
                <p>
                  Copyright © 2020 SeeMeet ®&nbsp; | &nbsp;{t("copyright")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Home.contextTypes = {
  t: PropTypes.func.isRequired
}

  const mapStateToProps = store => ({
    lang: store.i18nState.lang,
  });

export default withRouter(connect(mapStateToProps)(Home));
