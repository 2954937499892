import React, { useState, useEffect, useRef } from "react";
import { toastr } from "react-redux-toastr";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import Api from "../../../helpers/api";
import Loading from "../../../components/loading";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";

const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const Content = (props, context) => {
  const { t } = context;
  const { googleReCaptchaProps } = props;
  const [form, setForm] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [show, setShow] = useState(false);

  const onSave = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    const captcha = await googleReCaptchaProps.executeRecaptcha("form");
    let phone =
      form.phone && form.phone.replace(/\((.*)\)/, "").replace(/\s/g, "");
    phone = phone ? "90" + phone : null;
    const params = {
      ...form,
      phone: phone,
      captcha,
    };
    Api.postPromise("/form", params)
      .then((res) => {
        setShow(true);
        setSubmitLoading(false);
      })
      .catch((err) => {
        if (err && err.message) toastr.light(t("warningCap"), err.message);
        if (err && err.errorData) {
          let errors = err.errorData;
          const message = (
            <ul style={{ listStyle: "circle" }}>
              {Object.keys(errors).map((key, index) => (
                <li key={index} style={{ listStyle: "circle" }}>
                  {errors[key][0]}
                </li>
              ))}
            </ul>
          );

          toastr.light(
            t("warningCap"),
            { component: message },
            {
              timeOut: 20000,
            }
          );
        }
        setSubmitLoading(false);
      });
  };

  const bBottom = !form.email
    ? "1px solid #9e9e9e"
    : validateEmail(form.email)
    ? "1px solid green"
    : "1px solid red";
  const cColor = validateEmail(form.email) ? "green" : "red";
  const iClass = validateEmail(form.email) ? "fa fa-check" : "fa fa-times";
  return (
    <section className="account_area">
      <div className="container">
        <div className="row account_inner">
          <div className="col-lg-3" />
          <Loading loading={submitLoading}>
            {show ? (
              <div className="form-group col-lg-6">
                <h3>{t("thanks")}</h3>
                <form className="account_form_box">
                  {/*                      <form action="#" class="account_form_box" method="post" id="signupForm"> */}
                  <div className="form-group">
                    <p>{t("receivedMsg")}</p>
                    <p>{t("feedbackMsg")}</p>
                  </div>
                  <div className="form-group">&nbsp;</div>
                  <button
                    onClick={() => (window.location = "/")}
                    type="submit"
                    className="g_hover"
                  >
                    {t("backHome")}
                  </button>
                </form>
              </div>
            ) : (
              <div className="form-group col-lg-6">
                <h3>{t("contactForm")}</h3>
                <form onSubmit={onSave} className="account_form_box">
                  {/* <form action="#" class="account_form_box" method="post" id="signupForm"> */}
                  <div className="form-row">
                    <div className="form-group text_box col-md-6">
                      <label htmlFor="FirstName">{t("fullName")}</label>
                      <input
                        type="text"
                        onChange={(e) => {
                          const val = e.target.value;
                          setForm((f) => ({
                            ...f,
                            fullName: val,
                          }));
                        }}
                        value={form.fullName || ""}
                        name="FirstName"
                        id="FirstName"
                      />
                    </div>
                    <div className="form-group text_box col-md-6">
                      <label htmlFor="LastName">{t("phoneNumber")}</label>
                      <InputMask
                        type="text"
                        onChange={(e) => {
                          const val = e.target.value;
                          setForm((f) => ({
                            ...f,
                            phone: val,
                          }));
                        }}
                        maskPlaceholder={null}
                        mask="+\90 (999) 999 99 99"
                      />
                    </div>
                  </div>
                  <div className="form-group text_box">
                    <label htmlFor="Mail">{t("emailAddress")}</label>
                    <div className="w-100">
                      <input
                        style={{ borderBottom: bBottom }}
                        className="col-md-11"
                        type="email"
                        onChange={(e) => {
                          const val = e.target.value;
                          setForm((f) => ({
                            ...f,
                            email: val,
                          }));
                        }}
                        value={form.email || ""}
                        name="Mail"
                        id="Mail"
                      />
                      {form.email ? (
                        <i style={{ color: cColor }} className={iClass} />
                      ) : null}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group text_box col-md-6">
                      <label htmlFor="LastName">{t("companyName")}</label>
                      <input
                        type="text"
                        onChange={(e) => {
                          const val = e.target.value;
                          setForm((f) => ({
                            ...f,
                            company: val,
                          }));
                        }}
                        value={form.company || ""}
                        name="LastName"
                        id="LastName"
                      />
                    </div>
                    <div className="form-group text_box col-md-6">
                      <label htmlFor="Mail">{t("activityArea")}</label>
                      <input
                        type="text"
                        onChange={(e) => {
                          const val = e.target.value;
                          setForm((f) => ({
                            ...f,
                            activityName: val,
                          }));
                        }}
                        value={form.activityName || ""}
                        name="Mail"
                        id="Mail"
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group text_box col-md-6">
                      <label htmlFor="Password">{t("webAddress")}</label>
                      <input
                        onChange={(e) => {
                          const val = e.target.value;
                          setForm((f) => ({
                            ...f,
                            webAddress: val,
                          }));
                        }}
                        value={form.webAddress || ""}
                        type="text"
                        name="Password"
                        id="Password"
                      />
                    </div>
                    <div className="form-group text_box col-md-6">
                      <label htmlFor="Password2">{t("employeeCount")}</label>
                      <InputMask
                        mask="999999"
                        onChange={(e) => {
                          const val = e.target.value;
                          setForm((f) => ({
                            ...f,
                            employeeCount: val,
                          }));
                        }}
                        value={form.employeeCount || ""}
                        maskPlaceholder={null}
                      />
                    </div>
                  </div>
                  <div className="form-group text_box">
                    <label htmlFor="Mail">{t("yourMessage")}</label>
                    <input
                      onChange={(e) => {
                        const val = e.target.value;
                        setForm((f) => ({
                          ...f,
                          message: val,
                        }));
                      }}
                      value={form.message || ""}
                      type="text"
                      name="Mail"
                      id="Mail"
                    />
                  </div>
                  <div className="form-group text_box"
                    htmlFor="check-confirm"
                    style={{ fontSize: "14px", cursor: "pointer" }}
                  >
                    {t("captchaText", {
                      term: (
                        <a
                          target="_blank"
                          className="bold"
                          href="https://policies.google.com/terms"
                        >
                          Terms of Service
                        </a>
                      ),
                      policy: (
                        <a
                          target="_blank"
                          className="bold"
                          href="https://policies.google.com/privacy"
                        >
                          Privacy Policy
                        </a>
                      ),
                    })}
                  </div>
                  <button onClick={onSave} type="submit" className="g_hover">
                    {t("submit")}
                  </button>
                </form>
              </div>
            )}
          </Loading>

          <div className="col-lg-3" />
        </div>
      </div>
    </section>
  );
};

Content.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default withGoogleReCaptcha(withRouter(Content));
