import React from 'react';
import ReactDOM from 'react-dom';

import App from './app';
import { unregister as unregisterServiceWorker } from './worker'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

ReactDOM.render(<GoogleReCaptchaProvider
    reCaptchaKey="6LdhDjYaAAAAACzbXIEw6Xh5oQMRMqL_dp5Ow_Pt"
    language="tr"
    // useRecaptchaNet="[optional_boolean_value]"
    // scriptProps={{
    //   async: false, // optional, default to false,
    //   defer: false, // optional, default to false
    //   appendTo: "head", // optional, default to "head", can be "head" or "body",
    //   nonce: undefined // optional, default undefined
    // }}
  >
    <App />
  </GoogleReCaptchaProvider>, document.getElementById('root'));
unregisterServiceWorker();

