import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

const Header = ({}, context) => {
  const { t } = context;

  return (
    <header className="header_area p_absoulte m_p">
      <nav className="navbar navbar-expand-lg" id="header">
        <div className="container-fluid">
          <div className="logo_info">
            <div className="h_account_info">
              <a>{t("stayOnline")}</a>
              <a>{t("meetSafe")}</a>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

Header.contextTypes = {
  t: PropTypes.func.isRequired
}

export default withRouter(Header);
