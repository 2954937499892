import {kebabCase, toString, capitalize} from 'lodash';
import Api from './api';
import {persist, store} from '../store';
import {toastr} from 'react-redux-toastr';

const emptyMediaWarning = "Seçmiş olduğunuz konu anlatımının içeriği bulunmamaktadır. Lütfen farklı bir seçim yapınız."

const Utils = {
  getFormattedTime(totalSeconds) {
    var hours = Math.floor(totalSeconds / 3600);
    var minutes = Math.floor((totalSeconds - hours * 3600) / 60);
    var seconds = totalSeconds - hours * 3600 - minutes * 60;
    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    var time = hours + ':' + minutes + ':' + seconds;
    return time;
  },
  getFormattedDate(dateString) {
    if (dateString) {
      const dateObject = new Date(dateString);

      const date = this.dateToString(dateObject);
      const time = this.dateToHour(dateObject);

      return date + ' ' + time;
    } else return '';
  },
  getFirstDayOfWeek(date) {
    const dateObject = new Date(date);
    const day = dateObject.getDay(),
      diff = dateObject.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(dateObject.setDate(diff));
  },
  homeworkDateFormat(date) {
    const dateUnits = date.split(' ');
    const timeUnits = dateUnits[0].split('-');
    return timeUnits[2] + '.' + timeUnits[1] + '.' + timeUnits[0] + ' - ' + dateUnits[1];
  },
  turkishLetterToUpper(string) {
    let letters = {i: 'İ', ş: 'Ş', ğ: 'Ğ', ü: 'Ü', ö: 'Ö', ç: 'Ç', ı: 'I'};
    if (window.appLanguage === 'en') letters = {i: 'I', ş: 'S', ğ: 'G', ü: 'U', ö: 'O', ç: 'C', ı: 'I'};
    string = string.toString();
    string = string.replace(/(([iışğüçö]))/g, letter => {
      return letters[letter];
    });
    return string.toUpperCase();
  },
  completeDateToString(date) {
    let Y = toString(date.getFullYear());
    let M = toString(date.getMonth() + 1);
    let d = toString(date.getDate());
    let h = toString(date.getHours());
    let m = toString(date.getMinutes());
    let s = toString(date.getSeconds());

    if (M.length < 2) M = '0' + M;
    if (d.length < 2) d = '0' + d;
    if (h.length < 2) h = '0' + h;
    if (m.length < 2) m = '0' + m;
    if (s.length < 2) s = '0' + s;

    return Y + '-' + M + '-' + d + ' ' + h + ':' + m + ':' + s;
  },
  dateToString(date) {
    let Y = toString(date.getFullYear());
    let M = toString(date.getMonth() + 1);
    let d = toString(date.getDate());

    if (M.length < 2) M = '0' + M;
    if (d.length < 2) d = '0' + d;

    return d + '.' + M + '.' + Y;
  },
  dateToHour(date) {
    let h = toString(date.getHours());
    let m = toString(date.getMinutes());

    if (h.length < 2) h = '0' + h;
    if (m.length < 2) m = '0' + m;

    return h + ':' + m;
  },
  showCookieWarning() {
    window.addEventListener('load', function() {
      window.cookieconsent.initialise({
        palette: {
          popup: {
            background: '#252e39',
            text: '#ffffff',
          },
          button: {
            background: '#14a7d0',
          },
        },
        showLink: false,
        theme: 'classic',
        content: {
          message:
            'Sitemizden en iyi şekilde faydalanabilmeniz için çerezler kullanılmaktadır. Sitemize giriş yaparak çerez kullanımını kabul etmiş sayılıyorsunuz.',
          dismiss: 'Anladım',
        },
      });
    });
  },
  convertToKebabCase(string) {
    return string
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(x => x.toLowerCase())
      .join('-');
  },
  revertKebabCase(string) {
    return string.split('-').join(' ');
  },
  sortByValue(path, data) {
    return data.sort(function(a, b) {
      if (a[path] < b[path]) {
        return 1;
      }
      if (a[path] > b[path]) {
        return -1;
      }
      return 0;
    });
  },
  switchLogo() {
    const state = store.getState();
    const authInfo = (state.auth && state.auth.informations) || {};
    const id = authInfo.stoys_school_id ? +authInfo.stoys_school_id : 0;
    switch (id) {
      case 1:
        return '/img/logo-ugur.png';
      case 2:
        return '/img/logo-bahcesehir.png';
      default:
        return '/img/logo.png';
    }
  },
  capitalizeT(t) {
    return t
      .split(' ')
      .map(tt => {
        if (tt == 'ENGLISH') tt = 'ENGLİSH';
        return capitalize(tt)
          .replace(/i/g, 'ı')
          .replace(/İ/g, 'İ')
          .replace(/ı̇/g, 'i');
      })
      .join(' ');
  },
  groupBy(array, type){
    return array.reduce((groups, item) => {
      const val = item[type]
      groups[val] = groups[val] || []
      groups[val].push(item)
      return groups
    }, {})
  },
  subjectIsProperForAssignment(item){
    return item.is_fsk_content || item.is_sb_content || item.is_kkt_content || item.is_lecture_content
  },
  fascicleAssigmentFilter(fascicles){
    return fascicles.filter(item => item.totalMedia > 0)
  },
  teacherSubjectFilter(subjects){
    return subjects.filter(this.subjectIsProperForAssignment)
  },
  fascicleActiveFilter(fascicles){
    return fascicles.filter(item => (item.active && 
      (item.is_fsk_content || item.is_sb_content || item.is_kkt_content || item.is_lecture_content)
    ))
  },
  chapterActiveFilter(chapters){
    return chapters.filter(item => (item.is_active && 
      (item.is_fsk_content || item.is_sb_content || item.is_kkt_content || item.is_lecture_content)
    ))
  },
  getAssignmentSubjects(subjects){
    let selectedSubject = subjects.selected
    let filterSubjects = this.teacherSubjectFilter(subjects.items)
    return {...subjects, items : filterSubjects, 
      selected: this.subjectIsProperForAssignment(selectedSubject) ?  selectedSubject : filterSubjects[0]  }
  },
  emptyFascicleWarning(){
    toastr.warning(emptyMediaWarning)
  },
  renderLibraryItemTitle(type){
    let title = null
    switch(type){
      case 'ebook': title = 'E-KİTAP'; break;
      case 'video': title = 'DRAMA'; break;
      case 'audio-book': title = 'SESLİ KİTAP'; break;
      default: title = '';break;
    }
    return title
  },
  pushEvent(eventAction, eventCategory, params, eventValue, fieldsObject) {
    let eventLabel = {}
    const state = store.getState();
    let informations = state.auth.informations;
    let manager = state.general.manager.user;
    let guardian =  state.guardian.guardian;
    
    eventLabel = {
      ...params,
      userId: informations.user_id,
      UserName: informations.username,
      StoysId: informations.stoys_user_id,
      Timestamp: Date.now(),
      Category: eventCategory,
      Action: eventAction,
      Value: eventValue,
      Path: window.location.pathname
    }

    if(manager.user_id) {
      eventLabel.RootUserId = manager.user_id;
      eventLabel.RootStoysId = manager.stoys_user_id
    }
    
    if(guardian.user_id) {
      eventLabel.RootUserId = guardian.user_id;
      eventLabel.RootStoysId = guardian.stoys_user_id;
    }

    eventLabel = JSON.stringify(eventLabel);

    console.log('event', {
      eventAction,eventCategory,eventLabel,eventValue
    });

    if(typeof window.gtag != "undefined" && window.gtag) {
      window.gtag('event', eventAction, {
        'event_category': eventCategory, 
        'event_label': eventLabel,
        'value': eventValue
      });

      // google custom dimensions
      window.gtag('set', {user_id: informations.user_id});
      window.gtag('event', 'UserType', {UserType: informations.user_type});
      window.gtag('event', 'UserID', {UserID: informations.user_id});
      window.gtag('event', 'UserName', {UserName: informations.username});
      window.gtag('event', 'Brand', {Brand: (informations.stoys_school_id == 2) ? "Bahcesehir" : "Ugur"});
      window.gtag('event', 'School', {School: informations.school_name});
      window.gtag('event', 'Grade', {Grade: informations.grade});
      window.gtag('event', 'Branch', {Branch: informations.branch_name});
    }

    //trackEvent(category, action, [name], [value])
    // matomo event
    if(typeof window._paq != "undefined" && window._paq) {
      window._paq.push(['trackEvent', eventCategory, eventAction, eventLabel, eventValue]);

      // matomo custom dimensions
      window._paq.push(['setCustomDimension', 2, informations.stoys_school_id == 2 ? "Bahcesehir" : "Ugur"]);
      window._paq.push(['setCustomDimension', 3, informations.school_name]);
      window._paq.push(['setCustomDimension', 4, informations.grade]);
      window._paq.push(['setCustomDimension', 5, informations.branch_name]);
      window._paq.push(['setCustomDimension', 6, informations.user_type]);
      window._paq.push(['setCustomDimension', 7, informations.user_id]);

    }
  },

  setTitle(title) {
    document.title = title;
    window.gtag('title', document.title);
    window._paq.push(['setDocumentTitle', document.title]);
  }
};

export default Utils;
