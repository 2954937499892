import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import Authenticate from "../../HOC/authenticate";
import Layout from '../../components/layout';
import Header from '../../components/layout/header';

const DashboardContainer = (props, context) => {
  const { location } = props;

  useEffect(() => {
  }, [location]);


  return <Layout />;
};

export default Authenticate(
  withRouter(DashboardContainer)
);