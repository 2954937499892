import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import Home from "../../containers/dashboard/home";
const TeacherBoard = (props) => {
  const {} = props;

  return (
    <Switch>
      <Route exact path={"/"} component={Home} />
    </Switch>
  );
};

export default TeacherBoard;
