let backendhost = "";

const hostname = window && window.location && window.location.hostname;

if (
  hostname === "seemeet.live" ||
  hostname === "www.seemeet.live"
) {
  backendhost = "https://app.seemeet.live";
} else {
  backendhost = process.env.REACT_APP_LOGIN_HOST || "https://app.seemeet.live";
}

export const BASE_API = `${backendhost}`;
