import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Image from "../elements/image";

const jrLoadingStyle = {
  position: "relative",
  top: "0",
  left: "0",
  margin: "auto",
  width: "20%",
};

const Loading = (props) => {
  const { children, loading, permissions, noJr, type } = props;

  const renderLoading = () => {
    const { style, loadingStyle, className, divStyle } = props;
    return (
      <center style={{ margin: "auto" }}>
        <Image src="waiting.gif" style={style} />
      </center>
    );
  };

  return <Fragment>{(loading && renderLoading()) || children}</Fragment>;
};

const mapStateToProps = (store) => ({
  permissions: store.auth.permissions || {},
});

Loading.defaultProps = {
  type: "main",
  loading: false,
  noJr: false,
  className: "",
  loadingStyle: { width: 75 },
  style: { width: "100px" },
  divStyle: { minHeight: 400 },
};

Loading.propTypes = {
  loading: PropTypes.bool,
  style: PropTypes.object,
  loadingStyle: PropTypes.object,
  divStyle: PropTypes.object,
  className: PropTypes.string,
};

export default connect(
  mapStateToProps,
  null
)(Loading);
