import React from 'react';  
import { connect } from 'react-redux';  
import { withRouter } from 'react-router-dom';

export default function (ComposedComponent) {  
  class Authenticate extends React.Component {
    static propTypes = { };

    componentDidMount() {
      this._checkAndRedirect();
    }

    componentDidUpdate() {
      this._checkAndRedirect();
    }

    _checkAndRedirect() {
      // const { authInfo, designs } = this.props;
      // if (!(authInfo && authInfo.token) || (designs && designs.length == 0)) {
      //   // this.props.history.replace(urls.login)
      // }
    }

    render() {
      return (
        <div>
          { this.props.authInfo ? <ComposedComponent {...this.props} /> : null }
        </div>
      );
    }
  }

  const mapStateToProps = (store) => {
    return {
      authInfo: store.auth.informations,
      designs: store.auth.designs
    };
  };

  return withRouter(connect(
    mapStateToProps
  )(Authenticate));
}