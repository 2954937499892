

export const translations = {
  'tr': {
    stayOnline: "Çevrimiçi Kal",
    meetSafe: "Güvenli Görüş",

    homepage: "Anasayfa",
    login: "Oturum Aç",
    homepageCap: "ANASAYFA",
    loginCap: "OTURUM AÇ",
    register: "Kayıt Ol",
    downloadApp: "Uygulamayı İndir",
    previous: "ÖNCEKİ",
    next: "SONRAKİ",
    warningCap: "UYARI!",
    
    sectionOne1: "2020 yılının Mart ayı itibariyle dünyayı saran pandemi sebebiyle, sosyal ve iş hayatımızda değişiklikler başladı. Çevrimiçi uygulamaların hayatımızın doğal akışında gerekliliğinden yola çıkarak geliştirmeye başladığımız SeeMeet uygulamasıyla birlikteyiz.",
    sectionOne2: "SeeMeet, çevrimiçi tarayıcılar / uygulamalar aracılığı ile kullanabileceğiniz bir sosyal etkileşim, toplantı uygulamasıdır.",
    loginCap: "OTURUM AÇ",
    
    sectionTwoTitle: "SeeMeet, kullanırken içiniz rahat olsun;",
    sectionTwo1: "SeeMeet, kullanıcı verilerini üçüncü taraflara satmaz.",
    sectionTwo2: "SeeMeet, Türkiye içerisinde konumlandırılan bulut hizmetleri aracılığı ile hizmet sağlar.",
    sectionTwo3: "SeeMeet kullanıcı verilerini reklam için işlemez, çapraz pazarlama teknikleri için kullanmaz.",
    sectionTwo4: "SeeMeet üzerinde kullanıcı dikkati izleme özellikleri, ses tanıma, ayrıştırma temizleme yazılımı bulunmaz.",

    sectionThirdTitle: "Başlarken",
    sectionThird1: "Bireysel Kullanım",
    sectionThird2: "SeeMeet'i tüm bireysel görüşme ihtiyaçlarınız için ücretsiz olarak kullanabilirsiniz. Bir dakikadan daha kısa süre içerisinde üye olabileceğiniz SeeMeet'i kullanmaya;",
    startNowCap: "HEMEN BAŞLA",
    fireWallDoc: "Güvenlik Duvarı Tanımlama Dokümanı",
    userGuide: "Kullanım Kılavuzu",
    companyUsage: "Kurumsal Kullanım",
    sectionThird3: "Proje toplantıları, online eğitimler, müşteri görüşmeleri ve kurumunuzun ihtiyacı olabilecek tüm görüntülü görüşme ihtiyaçlarınızda SeeMeet'i kullanabilirsiniz. Bir dakikadan daha kısa süre içerisinde üye olabileceğiniz SeeMeet'i kullanmaya;",
    fillForm: "FORMU DOLDUR",
    
    sectionFourTitle: "SeeMeet'i nasıl kullanabilirim?",
    sectionFour1: "Yeni nesil web tarayıcıları üzerinden, yeni bir program indirmeden, kredi kartı vb. bilgilerinizi paylaşmaya gerek kalmadan kullanabilirsiniz.",
    sectionFour2: "SeeMeet kullanmak için bilgisayarınızda ses, mikrofon, kamera özelliklerinin olması gerekmektedir.",
    joinMeetingCap: "GÖRÜŞMEYE KATIL",
    createMeetingCap: "TOPLANTI OLUŞTUR",
    
    downloadApps: "Uygulamaları İndir",
    desktop: "Masaüstü",
    mobileApps: "Mobil Uygulamalar",
    downloadText1: "Windows, Mac OS ve Linux cihazlar için SeeMeet’i hızlıca indirip kullanmaya başlayabilirsin.",
    downloadText2: "SeeMeet mobil çözümleri geliştirmeleri devam ediyor.",
    downloadText3: "Çok yakında Apple Store ve Google Play üzerinden mobil uygulamalarına erişebileceksiniz.",
    downloadToComputerCap: "BİLGİSAYARINA İNDİR",
    soon: "ÇOK YAKINDA",
    
    contactUs: "Bize Ulaşın",
    productHelp: "SeeMeet ürünümüz ile bilgi almak için ilgili bize ulaşabilirsiniz.",
    helpDesk: "Yardım Masası",
    homeOffice: "Ankara Ofis",
    productText1: "SeeMeet bir",
    productText2: "ürünüdür.",
    address: "Ankara Teknoloji Geliştirme Bölgesi, Cyberplaza C Blok 3.Kat BK Mobil No:354 06800 Bilkent Çankaya /ANKARA",
    copyright: "Tüm hakları saklıdır.",

    contactForm: "İletişim Formu",
    fullName: "Adınız Soyadınız",
    phoneNumber: "Telefon Numaranız",
    emailAddress: "E-posta Adresiniz",
    companyName: "Şirket / Kurum Adı",
    activityArea:"Faaliyet Alanınız",
    webAddress: "Web Adresiniz",
    employeeCount: "Çalışan Sayınız",
    yourMessage: "Mesajınız",
    submit: "GÖNDER",
    thanks: "Teşekkürler",
    receivedMsg: "İletişim bilgilerinizi aldık.",
    feedbackMsg: "En kısa zamanda mail adresinizden size geri dönüş sağlayacağız.",
    backHome: "ANA SAYFAYA DÖN",
    captchaText: "Bu site reCAPTCHA ile korunmaktadır ve Google {policy} ile {term} geçerlidir.",
  },
  'en': {
    stayOnline: "Stay Connected",
    meetSafe: "Meet Safe",

    homepage: "Homepage",
    login: "Sign in",
    homepageCap: "HOMEPAGE",
    loginCap: "SIGN IN",
    register: "Register",
    downloadApp: "Download App",
    previous: "PREVIOUS",
    next: "NEXT",
    warningCap: "WARNING!",
    
    sectionOne1: "At the begining of March 2020 a pandemic surrounded our World and changed our lives drastically. Due to the this sudden life style change, our needs to e-social interaction and online meeting platform increased.",
    sectionOne2: "SeeMeet is a social interaction and online meeting platform, which can be use via online browsers and applications.",
    loginCap: "SIGN IN",
    
    sectionTwoTitle: "Use SeeMeet freely and safely",
    sectionTwo1: "SeeMeet does not sell user data to third parties.",
    sectionTwo2: "It provides services through highly secure cloud services in Turkey",
    sectionTwo3: "SeeMeet does not process any users' personal data, nor does sell to different companies for advertising and marketing.",
    sectionTwo4: "SeeMeet does not have user attention tracking features, voice recognition, parsing and cleaning software.",

    sectionThirdTitle: "Getting started",
    sectionThird1: "Personal use,",
    sectionThird2: "You can use SeeMeet, free of charge, for all your individual video conference needs. Become a member in less than a minute and;",
    startNowCap: "SeeMeet Now!",
    fireWallDoc: "Download Firewall Settings Manual",
    userGuide: "Download User Manual",
    companyUsage: "Corporate use,",
    sectionThird3: "You can use SeeMeet for your project meetings, online trainings, customer meetings and all other video conference needs. Become a member in less than a minute and;",
    fillForm: "Fill the form now!",
    
    sectionFourTitle: "How can I use SeeMeet?",
    sectionFour1: "You may start using SeeMeet via new generation web browsers, without downloading a new program or giving out your credit card information.",
    sectionFour2: "To use SeeMeet, you need to have sound, microphone and camera features on your computer.",
    joinMeetingCap: "JOIN MEETİNG",
    createMeetingCap: "CREATE MEETING",
    
    downloadApps: "Download Apps",
    desktop: "Desktop",
    mobileApps: "SeeMeet Mobile",
    downloadText1: "You can quickly download and use SeeMeet for Windows, Mac OS and Linux devices.",
    downloadText2: "SeeMeet mobile solution developments continues.",
    downloadText3: "You will soon be able to download our mobile applications from Apple Store and Google Play Store.",
    downloadToComputerCap: "DONWLOAD NOW!",
    soon: "COMING SOON",
    
    contactUs: "Contact Us",
    productHelp: "You can contact us for information about our SeeMeet.",
    helpDesk: "Help Desk",
    homeOffice: "Head Office",
    productText1: "SeeMeet is a",
    productText2: "Product.",
    address: "Ankara Teknoloji Geliştirme Bölgesi, Cyberplaza C Blok 3.Kat BK Mobil No:354 06800 Bilkent Çankaya /ANKARA",
    copyright: "All rights reserved.",

    contactForm: "Contact Form",
    fullName: "Name Surname",
    phoneNumber: "Phone Number",
    emailAddress: "Email Address",
    companyName: "Company Name",
    activityArea:"Activity Area",
    webAddress: "Web Address",
    employeeCount: "Employee Count",
    yourMessage: "Message",
    submit: "SUBMİT",
    thanks: "Thank You",
    receivedMsg: "We have received your contact information.",
    feedbackMsg: "We will get back to you as soon as possible.",
    backHome: "GO BACK HOMEPAGE",
    captchaText: "This site is protected by reCAPTCHA and the Google {policy} and {term} apply.",
  }
}