import axios from "axios";
import { BASE_API } from "./apiConfig";
import { persist, store } from "../store";

const apiKey = 'By0sSY1QY5vDwbhvSA9IEwPyzBE5D70ONaCu2r9onQlnYGDENRarX0KcLFABVlU6';

export function setHeaders(endpoint) {
  const state = store.getState();
  const authInfo = (state.auth && state.auth.informations) || {};
  const lang = (state.i18nState && state.i18nState.lang) || "tr";
  const token = authInfo.token;

  if(token){
    axios.defaults.headers.get["Authorization"] = `bearer ${token}`;
    axios.defaults.headers.post["Authorization"] = `bearer ${token}`;
    axios.defaults.headers.delete["Authorization"] = `bearer ${token}`;
    axios.defaults.headers.put["Authorization"] = `bearer ${token}`;
  }
  axios.defaults.headers.get["X-localization"] = lang;
  axios.defaults.headers.post["X-localization"] = lang;
  axios.defaults.headers.delete["X-localization"] = lang;
  axios.defaults.headers.put["X-localization"] = lang;
}

const Api = {
  get(endpoint, next) {
    setHeaders(endpoint);
    axios
      .get(BASE_API + endpoint)
      .then(response => {
        next(response.data, false, response);
      })
      .catch(error => {
        if (error.response) next(false, error.response.data);
      });
  },

  async getPromise(endpoint, params) {
    return new Promise((resolve, reject) => {
      setHeaders(endpoint);
      axios
        .get(BASE_API + endpoint+'?apiKey='+apiKey, params || {})
        .then(response => resolve(response.data))
        .catch(error =>
          reject(
            (error &&
              error.response &&
              error.response.data &&
              error.response.data.error) ||
              ""
          )
        );
    });
  },

  async putPromise(endpoint, params) {
    return new Promise((resolve, reject) => {
      setHeaders(endpoint);
      axios
        .put(BASE_API + endpoint+'?apiKey='+apiKey, params)
        .then(response => resolve(response.data))
        .catch(error => reject(error && error.response && error.response.data));
    });
  },

  async postPromise(endpoint, params, header) {
    return new Promise((resolve, reject) => {
      setHeaders(endpoint);
      axios
        .post(BASE_API + endpoint +'?apiKey='+apiKey, params, header)
        .then(response => resolve(response.data))
        .catch(error => reject(error && error.response && error.response.data));
    });
  },

  async getAsync(endpoint, params) {
    setHeaders(endpoint);
    try {
      const response = await axios.get(BASE_API + endpoint, params || {});
      return response.data;
    } catch (error) {
      return error.response && error.response.data && error.response.data.error;
    }
  },

  async postAsync(endpoint, params) {
    setHeaders(endpoint);
    try {
      const response = await axios.post(BASE_API + endpoint, params || {});
      return response.data;
    } catch (error) {
      return error.response && error.response.data && error.response.data.error;
    }
  },

  post(endpoint, params, next) {
    setHeaders(endpoint);
    axios
      .post(
        endpoint.indexOf("://") != -1 ? endpoint : BASE_API + endpoint,
        params
      )
      .then(response => {
        next(response.data, false);
      })
      .catch(error => {
        if (error.response && next)
          next(false, error.response || error.response.data);
      });
  },
  put(endpoint, params, next) {
    setHeaders(endpoint);
    axios
      .put(BASE_API + endpoint, params)
      .then(response => {
        next(response.data, false);
      })
      .catch(error => {
        if (error.response && next)
          next(false, error.response || error.response.data);
      });
  },
  delete(endpoint, params, next) {
    setHeaders(endpoint);

    axios({
      method: "DELETE",
      url: BASE_API + endpoint,
      data: params
    })
      .then(function(response) {
        next(response, false);
      })
      .catch(function(error) {
        if (error.response.data) next(false, error.response.data);
      });
  },
  paralel(endpoints, next) {
    setHeaders();
    let promises = [];
    endpoints.forEach(function(endpoint) {
      promises.push(axios.get(BASE_API + endpoint));
    });
    let responses = [];
    axios.all(promises).then(
      axios.spread((...args) => {
        for (let i = 0; i < args.length; i++) {
          responses.push(args[i].data);
        }
        next(responses);
      })
    );
  },
  paralelPost(endpointWithParams, next) {
    setHeaders();
    let promises = [];
    endpointWithParams.forEach(function(endpointWithParam) {
      promises.push(
        axios.post(
          BASE_API + endpointWithParam.endpoint,
          endpointWithParam.params
        )
      );
    });
    let responses = [];
    axios
      .all(promises)
      .then(
        axios.spread((...args) => {
          for (let i = 0; i < args.length; i++) {
            responses.push(args[i].data);
          }
          next(responses, false);
        })
      )
      .catch(function(error) {
        next(false, error.response.data);
      });
  },
  serialGet(endpoints, next) {
    setHeaders();
    let promiseCount = endpoints.length;
    let errorCount = 0;
    let responseCount = 0;
    let responses = [];
    let errors = [];
    let i = 0;
    endpoints.forEach(function(endpoint) {
      let index = i++;
      axios
        .get(BASE_API + endpoint, index)
        .then(response => {
          responses[index] = response.data;
          responseCount++;
          if (responseCount + errorCount === promiseCount) {
            next(responses, errors);
          }
        })
        .catch(error => {
          if (error.response) {
            errors[index] = error.response.data;
            errorCount++;
            if (responseCount + errorCount === promiseCount) {
              next(responses, errors);
            } else {
              console.log("ERROR: " + JSON.stringify(error));
            }
          }
        });
    });
  },
  serialPutPost(endpointWithParams, next) {
    setHeaders();
    let promiseCount = endpointWithParams.length;
    let errorCount = 0;
    let responseCount = 0;
    let responses = [];
    let errors = [];
    let i = 0;
    endpointWithParams.forEach(function(endpointWithParam) {
      let index = i++;
      axios[endpointWithParam.method](
        BASE_API + endpointWithParam.endpoint,
        endpointWithParam.params,
        index
      )
        .then(response => {
          responses[index] = response.data;
          responseCount++;
          if (responseCount + errorCount === promiseCount) {
            next(responses, errors);
          }
        })
        .catch(error => {
          console.log(error);
          errors[index] = error.response.data;
          errorCount++;
          if (responseCount + errorCount === promiseCount) {
            next(responses, errors);
          }
        });
    });
  },
  paralelPut(endpointWithParams, next) {
    setHeaders();
    let promises = [];

    endpointWithParams.forEach(function(endpointWithParam) {
      promises.push(
        axios.put(
          BASE_API + endpointWithParam.endpoint,
          endpointWithParam.params
        )
      );
    });
    let responses = [];
    axios
      .all(promises)
      .then(
        axios.spread((...args) => {
          for (let i = 0; i < args.length; i++) {
            responses.push(args[i].data);
          }
          next(responses, false);
        })
      )
      .catch(function(error) {
        next(false, error.response.data);
      });
  },
  paralelAll(endpointWithParams, next) {
    setHeaders();
    let promises = [];
    endpointWithParams.forEach(function(endpointWithParam) {
      if (
        endpointWithParam.method === "delete" ||
        endpointWithParam.method === "get"
      ) {
        promises.push(
          axios[endpointWithParam.method](BASE_API + endpointWithParam.endpoint)
        );
      } else promises.push(axios[endpointWithParam.method](BASE_API + endpointWithParam.endpoint, endpointWithParam.params));
    });
    let responses = [];
    axios
      .all(promises)
      .then(
        axios.spread((...args) => {
          for (let i = 0; i < args.length; i++) {
            responses.push(args[i].data);
          }
          next(responses, false);
        })
      )
      .catch(function(error) {
        console.log(error);
        next(false, error.response.data);
      });
  },
  getLocalStorageAuth() {
    const persist = JSON.parse(localStorage.getItem("persist:lcms:root"));
    const auth = persist ? JSON.parse(persist.auth) : null;

    return (auth && auth.informations) || {};
  }
};

export default Api;
