import React from "react";
import PropTypes from "prop-types";
import Header from "./header";
import Footer from "./footer";
import MainBoard from "../../router/main";
import { withRouter } from "react-router-dom";
import Sidebar from "./sidebar";

const Layout = ({}, context) => {
  const {t} = context;
  const renderBoard = () => {
    return <MainBoard />;
  };

  return (
    <>
      <Header />
      <Sidebar />
      {renderBoard()}
      <Footer translate={t}/>
    </>
  );
};

Layout.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default withRouter(Layout);
