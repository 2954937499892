import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ReduxToastr, {actions} from 'react-redux-toastr';
import Dashboard from "../containers/dashboard";
import Form from "../containers/form";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import { setTranslations } from "redux-i18n";
import { connect } from "react-redux";
import Axios from "axios";
import ErrorCatch from "../errorCatch";

const AppRoutes = props => {
  const {clean} = props;
  const [loading, setLoading] = useState(true);

  // const formatTranslations = (object, depth) => {
  //   let result = {};
  //   for (var i in object) {
  //     const newKey = depth ? depth + '.' + i : i;
  //     if (typeof object[i] !== 'object') {
  //       result[newKey] = object[i];
  //     } else {
  //       result = {...result, ...formatTranslations(object[i], newKey)};
  //     }
  //   }

  //   return result;
  // };
 
  useEffect(() => {
    clean();
  }, []);

  // useEffect(() => {
  //   maintenan();
  // }, [location]);

  // const fetchLang = async lang => {
  //   lang = lang == 'en' ? 'en' : 'tr';
  //   const response = await (await fetch('https://static.metodbox.com/langs/' + lang + '/web.json')).json();

  //   const langs = formatTranslations(response);
  //   setTranslations(langs, lang);
  // };

  return (
    <BrowserRouter>
      <ErrorCatch>
        <>
          <Switch>
            <Route name="form" path={"/form"} component={Form} />
            <Route name="dashboard" path={"/"} component={Dashboard} />
          </Switch>
          <ReduxToastr
            timeOut={6000}
            newestOnTop={false}
            preventDuplicates
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick
          />
        </>
      </ErrorCatch>
    </BrowserRouter>
  );
};
const mapStateToProps = store => ({
});

const mapDispatchToProps = {
  clean: actions.clean,
  // setTranslations
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppRoutes);

